import { formatCollaborator } from './formatCollaborator';
import { formatDate } from './formatDate';

export const formatLinkedData = (array) => {
  const isLinkedFormat = array.map((item) => {
    const onboarding = item.onboarding;
    const customer = item.customer;
    const created_at_linked = item?.created_at
      ? formatDate(item?.created_at, 'dd/MM/yyyy - HH:mm:ss')
      : false;
    const unbind_at = item?.updated_at
      ? formatDate(item?.updated_at, 'dd/MM/yyyy - HH:mm:ss')
      : false;

    return {
      ...item,
      ...onboarding,
      customer,
      unbind_at,
      created_at_linked,
      is_active: item.is_active,
    };
  });

  return isLinkedFormat?.map((item) => formatCollaborator(item));
};
