import axios from 'axios';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import CancelButton from '../../../../../../components/CancelButton';
import ConfirmButton from '../../../../../../components/ConfirmButton';
import { Details } from '../../../../../../components/Details';
import {
  IconArrow,
  IconCalendar,
  IconCollaborator,
  IconDocument,
  IconDocumentPerson,
  IconEyes,
  IconKey,
  IconOutCurrency,
  IconStatus,
} from '../../../../../../components/Details/Icons';
import Status from '../../../../../../components/UI/Status';
import { useInvoice } from '../../../../../../hooks/useInvoice';
import { useNotification } from '../../../../../../hooks/useNotifications';
import { useUser } from '../../../../../../hooks/useUser';
import { useView } from '../../../../../../hooks/useView';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatMoney } from '../../../../../../utils/formatMoney';
import {
  IconAprove,
  IconDownloadImg,
  IconImage,
  IconRefuse,
} from '../../../icons';
import styles from '../../styles.module.scss';
import { CardCopy } from '../CardCopy';

export default function CardList({ invoice, listSelect, toggleSelect, index }) {
  const { user } = useUser();
  const [isCopied, setIsCopied] = useState(false);
  const [card, setCard] = useState({ index: false, status: false });
  const { view, ref } = useView({
    /* Optional options */
    rootMargin: '100px 0px 0px 0px',
    root: null,
    threshold: 0.5,
  });
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { fetchReceiptKeyApprove, fetchReceiptKeyRefuse } = useInvoice();
  const { addNotification } = useNotification();

  const handleCopyClick = (text) => {
    setIsCopied(text);

    setInterval(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleCancel = async () => {
    return;
  };
  const handleApproved = async () => {
    return;
  };
  const textButtonConfirm = {
    initial: 'Aprovar',
    confirm: 'Confirmar',
    loading: 'Carregando...',
  };
  const textButtonCancel = {
    initial: 'Cancelar',
    confirm: 'Confirmar',
    loading: 'Carregando...',
  };
  /**
   * Faz o download do arquivo Imagem associado a nota fiscal.
   *
   * @async
   * @function handleDownload
   * @throws {Error} Se ocorrer um erro durante o download
   */
  const handleDownload = async () => {
    try {
      const uniqueUrl = `${invoice?.url}?_=${new Date().getTime()}`;
      const response = await axios.get(uniqueUrl, {
        responseType: 'blob',
        headers: {
          Accept:
            'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
      const blob = await response.data;

      saveAs(
        blob,
        `Nota-Fiscal_${invoice?.source_company?.accountable}_${
          invoice?.invoice_number
        }_${formatDate(invoice?.invoice_date)}.jpg`,
      );

      addNotification('Nota fiscal baixada com sucesso!', 3, 'Atenção', true);
    } catch (error) {
      console.error('Erro ao baixar a imagem:', error);
      addNotification(
        'Erro ao obter a nota, tente novamente.',
        2,
        'Erro',
        true,
      );
    }
  };

  const statusChecked = [6].includes(invoice?.status?.id);

  async function handleReceiptApprove() {
    setLoading({ approve: true, cancel: false });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          await fetchReceiptKeyApprove(key);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento das notas:', globalError);
    } finally {
      setLoading(false);
      closeCard();
      handleReload();
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de aprovação',
        false,
      );
    }
  }
  async function handleReceiptRefuse() {
    setLoading({ approve: false, cancel: true });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          await fetchReceiptKeyRefuse(key);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento das notas:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      closeCard();
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de cancelamento',
        false,
      );
    }
  }

  function closeCard() {
    setCard({ index: false, status: true });
  }

  async function handleReload() {
    await queryClient.invalidateQueries(['InvoiceList']);
  }

  return (
    <>
      <tr key={invoice?.key}>
        <td className={styles.input_select_blue}>
          <input
            type="checkbox"
            checked={listSelect && listSelect.includes(invoice?.key)}
            onChange={() => toggleSelect(invoice?.key)}
          />
        </td>
        <td>
          <CardCopy
            isCopied={isCopied}
            handleCopyClick={handleCopyClick}
            text={invoice?.key}
            id={invoice?.key}
          />
        </td>
        <td>{invoice?.invoice_number ?? '---'}</td>
        <td>{invoice?.source_company?.accountable}</td>
        <td>{invoice?.source_company?.cnpj}</td>
        <td>{formatMoney(invoice?.amount)}</td>
        <td>{invoice?.cnae ?? '---'}</td>

        <td className={styles.status}>
          <Status status={invoice?.status} />
        </td>
        <td
          className={styles.eyes}
          onClick={() => setCard({ index, status: true })}
        >
          <IconEyes size="1.3rem" color="var(--gray-500)" />
        </td>
      </tr>
      {card.index === index && card.status ? (
        <div className={styles.flexCard}>
          <div className={styles.header_flexCard}>
            <h2>Detalhes</h2>
            <button className={styles.close} onClick={() => closeCard()}>
              Fechar
              <MdClose size={'1.5rem'} />
            </button>
          </div>
          <div className={styles.details}>
            {!view && (
              <div className={styles.ico}>
                <IconArrow />
              </div>
            )}
            {invoice?.key && (
              <Details
                Icon={IconKey}
                title={'Chave:'}
                value={invoice?.key}
                copy={true}
              />
            )}
            {invoice?.invoice_number && (
              <Details
                Icon={IconKey}
                title={'Número:'}
                value={invoice?.invoice_number}
                copy={true}
              />
            )}
            {invoice?.source_company?.accountable && (
              <Details
                Icon={IconCollaborator}
                title={'Nome:'}
                value={invoice?.source_company?.accountable}
              />
            )}
            {invoice?.source_company?.cnpj && (
              <Details
                Icon={IconDocumentPerson}
                title={'CNPJ:'}
                value={invoice?.source_company?.cnpj}
                copy={true}
              />
            )}
            {invoice?.cnae && (
              <Details
                Icon={IconDocumentPerson}
                title={'CNAE:'}
                value={invoice?.cnae || invoice?.service_code}
                copy={true}
              />
            )}

            {invoice?.date && (
              <Details
                Icon={IconCalendar}
                title={'Lançamento'}
                value={invoice?.date}
              />
            )}

            {invoice?.error_description && (
              <Details
                Icon={IconStatus}
                title={invoice?.status?.id === 7 ? 'Error:' : 'Atenção:'}
                value={invoice?.error_description}
              />
            )}

            {invoice?.status && (
              <Details
                Icon={IconStatus}
                title={'Status'}
                value={invoice?.status?.text}
              />
            )}
            {invoice?.amount > 0 && (
              <Details
                Icon={IconOutCurrency}
                title={'Valor'}
                value={formatMoney(invoice?.amount)}
              />
            )}
            {invoice?.url && (
              <Details
                Icon={IconDocument}
                title={'Arquivo:'}
                value={
                  <div className={styles.box_content}>
                    <img
                      id="invoice"
                      className={styles.img}
                      src={invoice?.url}
                      alt="Invoice"
                    />
                    <div className={styles.box}>
                      <h2>Arquivo</h2>
                      <span style={{ color: '#666' }}>
                        <IconImage />
                        <a href={invoice?.url} target="_blank" rel="noreferrer">
                          Visualizar nota
                        </a>
                      </span>
                      <button onClick={handleDownload}>
                        <IconDownloadImg /> Download
                      </button>
                    </div>
                  </div>
                }
                description={true}
                padding="0.39rem 0.89rem"
              />
            )}
            <div
              className={styles.footer_buttons}
              style={{ display: invoice?.status?.id !== 1 ? 'none' : '' }}
            >
              <button
                disabled={invoice?.status?.id !== 1 || !!loading}
                className={styles.refuse}
                onClick={handleReceiptRefuse}
              >
                Reprovar
                <IconRefuse size={22} />
              </button>
              <button
                disabled={invoice?.status?.id !== 1 || !!loading}
                className={styles.approve}
                onClick={handleReceiptApprove}
              >
                Aprovar
                <IconAprove />
              </button>
            </div>

            <div ref={ref} className={styles.bottom}></div>
            {statusChecked && (
              <>
                <div className={styles.wrapper_button}>
                  {[72].every((item) => user?.permission.includes(item)) && (
                    <CancelButton
                      onConfirm={handleCancel}
                      textButton={textButtonCancel}
                      stylesCustom={{ width: '100%' }}
                    />
                  )}
                  {[71].every((item) => user?.permission.includes(item)) && (
                    <ConfirmButton
                      stylesCustom={{ width: '100%' }}
                      onConfirm={handleApproved}
                      textButton={textButtonConfirm}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
