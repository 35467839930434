export function separateFee(orders) {
  return orders.flatMap((order) => {
    if (order.fee_amount) {
      const orderSemTaxa = {
        ...order,
        amount: order.amount,
        OFX: {
          ...order.OFX,
          TRNAMT: order.amount,
          TRNTYPE: order.OFX.TRNTYPE || '',
        },
      };

      const orderTaxa = {
        ...order,
        amount: order.fee_amount,
        title: {
          ...order.title,
          description:
            order.OFX.TRNTYPE === 'CREDIT'
              ? 'Estorno de Taxa'
              : 'Taxa da transação',
        },
        OFX: {
          ...order.OFX,
          TRNAMT: parseFloat(-order.fee_amount).toFixed(2),
          TRNTYPE: order.OFX.TRNTYPE === 'CREDIT' ? 'CREDIT' : 'FEE',
          MEMO:
            order.OFX.TRNTYPE === 'CREDIT'
              ? 'Estorno de taxa de transação'
              : order?.title?.description.toLowerCase().includes('taxa')
              ? order?.title?.description
              : `Taxa referente à ${order?.title?.description}` ||
                'Cobrança de taxa',
        },
      };

      return [orderSemTaxa, orderTaxa];
    }
    return order;
  });
}
