import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatAccount } from '../../../utils/formatAccount';
import { formatDocument } from '../../../utils/formatDocument';
import { formatMethodPayment } from '../../../utils/formatMethodPayment';
import { formatMoney } from '../../../utils/formatMoney';
import { nameAbbreviate } from '../../../utils/formatName';
import { getBank, handleBankCodeName } from '../components/Utils';

export const receiveSchema = {
  details: [
    {
      object: 'movement',
      field: 'amount',
      translate: 'Valor',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'method',
      translate: 'Tipo de transação',
      handle: (v) => formatMethodPayment(v),
    },
    {
      object: 'movement',
      field: 'pix_end_to_end_id',
      translate: 'E2E',
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
      handle: (v) => v,
    },
    {
      field: ['additional_data', 'description'],
      translate: 'Descrição',
    },
  ],
  origin: [
    {
      object: 'movement',
      field: 'name',
      translate: 'Nome',
      handle: (v) => nameAbbreviate(v),
    },
    {
      object: 'movement',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'movement',
      field: ['bank_name', 'bank_code'],
      translate: 'Instituição',
      handle: (v) => {
        if (v.length > 9) return v;
        else return getBank(v);
      },
    },
    {
      field: 'pix_key',
      translate: 'Chave PIX',
      handle: (v) => {
        if (v.length >= 11 || v.length <= 14) {
          return formatDocument(v);
        }
        return v;
      },
    },
    {
      field: 'branch',
      translate: 'Agência',
    },
    {
      field: 'account',
      translate: 'Conta',
    },
    {
      field: 'account_type',
      translate: 'Tipo de conta',
      handle: (v) => formatAccount(v),
    },
  ],
  destiny: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleBankCodeName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v);
      },
    },
  ],
};
