import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatDate } from '../../../utils/formatDate';
import { formatDocument } from '../../../utils/formatDocument';
import { formatMethodPayment } from '../../../utils/formatMethodPayment';
import { formatMoney } from '../../../utils/formatMoney';
import { nameAbbreviate } from '../../../utils/formatName';
import { getBank, handleBankCodeName } from '../components/Utils';

export const slipSchema = {
  details: [
    {
      object: 'movement',
      field: 'amount',
      translate: 'Valor',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const formattedApprovedAt = formatDate(v, 'HH:mm');

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'method',
      translate: 'Tipo de transação',
      handle: (v) => formatMethodPayment(v),
    },
    {
      field: 'expiration_date',
      translate: 'Vencimento',
      handle: (v) => {
        const formattedApprovedAt = formatDate(v);

        return formattedApprovedAt;
      },
    },
    {
      field: 'fee_amount',
      translate: 'Acréscimo',
      handle: (v) => formatMoney(v),
    },
    {
      field: 'amount',
      translate: 'Valor total',
      handle: (v) => formatMoney(v),
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
    },
    {
      field: ['additional_data', 'description'],
      translate: 'Descrição',
    },
  ],
  destiny: [
    {
      object: 'movement',
      field: ['recipient_name'],
      translate: 'Nome',
      handle: (v) => nameAbbreviate(v),
    },
    {
      object: 'movement',
      field: ['bank_name_recipient'],
      translate: 'Instituição',
    },
    {
      object: 'movement',
      field: ['min_amount'],
      translate: 'Pagamento mínimo',
      handle: (v) => formatMoney(v),
    },
    {
      object: 'movement',
      field: ['max_amount'],
      translate: 'Pagamento máximo',
      handle: (v) => formatMoney(v),
    },
    {
      object: 'movement',
      field: ['fee_amount'],
      translate: 'Taxas/Juros',
      handle: (v) => formatMoney(v),
    },
    {
      object: 'movement',
      field: ['bank_slip'],
      translate: 'Código de barras',
    },
  ],
  origin: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleBankCodeName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v, 'fullName');
      },
    },
  ],
};
