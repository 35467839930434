export const splitTextIntoParagraphs = (text) =>
  text
    .toString()
    .split('\n')
    .filter((line) => (line?.trim() !== '' ? line.trim() : null));

export const splitSections = (paragraphs) => {
  let currentSection = [];
  let sections = [];

  paragraphs.forEach((para) => {
    if (
      para.trim() === 'BREAK_LINE_CONTRACT' ||
      para.trim() === '{BREAK_LINE_CONTRACT}'
    ) {
      if (currentSection.length > 0) {
        sections.push([...currentSection]);
      }
      currentSection = [];
    } else {
      currentSection.push(para.trim());
    }
  });

  if (currentSection.length > 0) {
    sections.push([...currentSection]);
  }

  return sections;
};

export const extractTableNumber = (marker) => {
  const regex = /\{TABLE_(\d+)\}/;
  const match = marker.match(regex);

  return match ? Number(match[1]) - 1 : 0;
};

// Função para converter a string de tabela em componentes React
export const parseTableString = (tableString) => {
  // Remover espaços em branco indesejados
  const cleanString = tableString.replace(/\s+/g, ' ').trim();

  // Separar os elementos da tabela
  const parts = cleanString
    .split(/(\{\/?\w+\})/g)
    .filter(Boolean)
    .filter((v) => v.trim().length);

  return groupTableElements(parts);
};

function groupTableElements(elements) {
  const stack = []; // Pilha para acompanhar a hierarquia de rows e cols
  let current = []; // Elemento atual que está sendo montado

  elements.forEach((element) => {
    switch (element) {
      case '{table_begin}':
        stack.push(current); // Iniciar a tabela e empilhar o array atual
        current = { type: 'table', content: [] };
        break;

      case '{row}':
        stack.push(current); // Iniciar uma nova row e empilhar o array atual
        current = { type: 'row', content: [] };
        break;

      case '{colHeader}':
        stack.push(current); // Iniciar uma nova colHeader e empilhar o array atual
        current = { type: 'colHeader', content: [] };
        break;

      case '{col}':
        stack.push(current); // Iniciar uma nova col e empilhar o array atual
        current = { type: 'col', content: [] };
        break;

      case '{/colHeader}':
      case '{/col}':
        // Fechar colHeader ou col, desempilhar o item anterior e adicionar o atual
        const completedCol = current;
        current = stack.pop();
        current.content.push(completedCol);
        break;

      case '{/row}':
        // Fechar row, desempilhar o item anterior e adicionar o atual
        const completedRow = current;
        current = stack.pop();
        current.content.push(completedRow);
        break;

      case '{table_end}':
        // Fechar tabela, desempilhar o item anterior e adicionar o atual
        const completedTable = current;
        current = stack.pop();
        current.push(completedTable);
        break;

      default:
        // Caso seja texto simples, adicionar ao conteúdo do elemento atual
        current.content.push(element);
        break;
    }
  });

  return current;
}

export const formatContract = (contract) => {
  let paragraphs = splitTextIntoParagraphs(contract).filter(
    (text) => text.trim() !== '.',
  );

  paragraphs = paragraphs.filter((text) => text[0] !== '§');

  const sections = splitSections(paragraphs);

  return sections.length ? sections : [];
};

export function mergeContract(contractObject) {
  const referenceTags = [
    '{CENTER}',
    '{BOLD}',
    '{table_begin}',
    '{row}',
    '{colHeader}',
    '{col}',
    '{/colHeader}',
    '{/col}',
    '{/row}',
    '{table_end}',
  ];

  // Extraindo os campos necessários
  const { contract, contract_template } = contractObject;
  const template = contract_template?.template || '';

  // Função para verificar se a string contém uma referência válida
  const containsReferenceTag = (str) =>
    referenceTags.some((tag) => str.includes(tag));

  // Combina as informações do contrato com o template, preservando as tags
  const mergedContract = template.replace(/\{.*?\}/g, (tag) => {
    // Se o tag está em contract, usa o valor dele
    const regex = new RegExp(`${tag}`, 'g');
    return containsReferenceTag(tag) && contract.match(regex) ? tag : tag;
  });

  // Retorna o objeto original com o novo campo 'merged_contract'
  return {
    ...contractObject,
    merged: mergedContract.toString(),
  };
}

export function splitContractContent(mergedContract) {
  // const tableRegex = /\{table_begin\}.*?\{table_end\}/gs; // Identifica tabelas entre {table_begin} e {table_end}
  // const tableRegexWithoutBraces = /table_begin.*?table_end/gs; // Identifica tabelas entre table_begin e table_end sem {}
  const tableRegex = /\{?table_begin\}?[\s\S]*?\{?table_end\}?/gs; // Identifica tabelas entre {table_begin} e {table_end} com ou sem {}

  // Encontra todas as tabelas
  const tables = mergedContract.match(tableRegex) || [];

  // Remove as tabelas do texto principal
  const textContent = mergedContract.replace(tableRegex, '').trim();

  // Junta todas as tabelas encontradas em uma única string
  const tableContent = tables.join('\n\n');

  // Retorna as partes separadas
  return {
    textContent,
    tableContent,
  };
}
