const statusLinked = [
  {
    idOnboardingStatus: 1,
    description: 'Aguardando realização do onboarding',
    text: 'Criado',
    color: 'var(--blue-status)',
    colorText: 'var(--blue-text)',
    id: 1,
  },
  {
    idOnboardingStatus: 2,
    description: 'Aguardando Análise',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 3,
    description: 'Aprovado',
    text: 'Aprovado',
    color: 'var(--green-status)',
    colorText: 'var(--green-text)',
    id: 3,
  },
  {
    idOnboardingStatus: 4,
    description: 'Rejeitado',
    text: 'Rejeitado',
    color: 'var(--orange-status)',
    colorText: 'var(--orange-text)',
    id: 4,
  },
  {
    idOnboardingStatus: 5,
    description: 'Análise Manual',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 6,
    description: 'Aguardando Assinatura',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 7,
    description: 'Aguardando Finalização',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 8,
    description: 'Desativado',
    text: 'Cancelado',
    color: 'var(--gray-50) ',
    colorText: 'var(--gray-500) ',
    id: 8,
  },
];

export const type = [
  {
    idOnboardingType: 1,
    text: 'Cadastro de agregado (conta)',
  },
  {
    idOnboardingType: 2,
    text: 'Cadastro de empresa',
  },
  {
    idOnboardingType: 3,
    text: 'Cadastro de Motorista',
  },
];
export const status = [
  {
    idOnboardingStatus: 1,
    description: 'Aguardando realização do onboarding',
    text: 'Criado',
    color: 'var(--blue-status)',
    colorText: 'var(--blue-text)',
    id: 1,
  },
  {
    idOnboardingStatus: 2,
    description: 'Aguardando Análise',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 3,
    description: 'Aprovado',
    text: 'Aprovado',
    color: 'var(--green-status)',
    colorText: 'var(--green-text)',
    id: 3,
  },
  {
    idOnboardingStatus: 4,
    description: 'Rejeitado',
    text: 'Rejeitado',
    color: 'var(--orange-status)',
    colorText: 'var(--orange-text)',
    id: 4,
  },
  {
    idOnboardingStatus: 5,
    description: 'Análise Manual',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 6,
    description: 'Aguardando Assinatura',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 7,
    description: 'Aguardando Finalização',
    text: 'Aguardando',
    color: 'var(--yellow-status)',
    colorText: 'var(--yellow-text)',
    id: 2,
  },
  {
    idOnboardingStatus: 8,
    description: 'Desativado',
    text: 'Cancelado',
    color: 'var(--gray-50) ',
    colorText: 'var(--gray-500) ',
    id: 8,
  },
];
export const typeDocument = [
  {
    id: 1,
    text: 'RG',
  },
  {
    id: 2,
    text: 'CNH',
  },
  {
    id: 3,
    text: 'RNE',
  },
  {
    id: 4,
    text: 'CRLV',
  },
];

const filterStatus = statusLinked.reduce((uniqueStatus, item) => {
  const existingStatus = uniqueStatus.find(
    (status) => status.value === item.id,
  );
  if (!existingStatus) {
    uniqueStatus.push({
      value: item.id,
      label: item.text,
    });
  }
  return uniqueStatus;
}, []);

export const statusCheck = filterStatus;
