import { formatStatusInvoice } from './formatStatus';

export function formatReceiptsWithCompanies(receipts, companies) {
  return receipts.map((receipt) => {
    const sourceCompany = companies.find(
      (company) => company.company_id === receipt.source_company_id,
    );
    const targetCompany = companies.find(
      (company) => company.company_id === receipt.target_company_id,
    );

    return {
      ...receipt,
      status: formatStatusInvoice(receipt?.status),
      amount: receipt?.amount ?? 0,
      cnae: receipt?.service_code,
      source_company: sourceCompany ? sourceCompany : null,
      target_company: targetCompany ? targetCompany : null,
    };
  });
}
