import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import CryptoJS from 'crypto-js';
import addDocument from '../../../../Assets/add-document.png';
import signature from '../../../../Assets/signature.png';
import view from '../../../../Assets/view.png';
import {
  extractTableNumber,
  mergeContract,
  parseTableString,
  splitContractContent,
  splitSections,
  splitTextIntoParagraphs,
} from '../../../../utils/formatContract';
import { formatDate } from '../../../../utils/formatDate';
import { formatDocument } from '../../../../utils/formatDocument';
import {
  style_contract,
  style_history,
  style_signatures,
  style_tables,
} from './styles';

export default function Contract({ data, id, contractNumber }) {
  const history = [];
  const hash = CryptoJS.SHA256(`${id}-${contractNumber}`).toString(
    CryptoJS.enc.Hex,
  );
  const contract = mergeContract(data);
  const content = splitContractContent(contract.merged);
  const tables = parseTableString(content.tableContent);

  let paragraphs = splitTextIntoParagraphs(
    splitContractContent(data.contract).textContent,
  ).filter((text) => text.trim() !== '.');

  paragraphs = paragraphs.filter((text) => text[0] !== '§');

  const signatures = data.history.filter(
    (ass) => ass.type === 3 || ass.type === 5,
  );

  paragraphs = paragraphs.filter(
    (para) => !para.trim().startsWith('{table_begin}'),
  );

  const sections = splitSections(paragraphs);

  data.history.forEach((current, i) => {
    if (i === 0) history.push({ ...current, icon: 'addDocument' });
    else if (i % 2 === 0) history.push({ ...current, icon: 'signature' });
    else history.push({ ...current, icon: 'view' });
  });

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const pages = sections[parseInt(contractNumber) - 1] || sections[0];

  function handleTable(data) {
    return tables[extractTableNumber(data)].content;
  }

  function renderTable(elementsArray) {
    return elementsArray.map((element, index) => {
      // Se for uma string simples (conteúdo de uma célula), renderiza diretamente como Text
      if (typeof element === 'string') {
        return (
          <Text key={index} style={style_tables.tableRowSeparate}>
            {element}
          </Text>
        );
      }

      // Se for um objeto, verifica o tipo (table, row, col, colHeader)
      if (typeof element === 'object' && element.type) {
        switch (element.type) {
          case 'table':
            return (
              <View key={index} style={style_tables.table}>
                {renderTable(element.content)}
              </View>
            );

          case 'row':
            return (
              <View key={index} style={style_tables.tableRow}>
                {renderTable(element.content)}
              </View>
            );

          case 'colHeader':
            return (
              <View key={index} style={style_tables.tableColHeader}>
                {renderTable(element.content)}
              </View>
            );

          case 'col':
            return (
              <View key={index} style={style_tables.tableCol}>
                {renderTable(element.content)}
              </View>
            );

          default:
            return null; // Se o tipo não for reconhecido, não renderiza nada
        }
      }

      return null; // Se não for nem string nem objeto válido, não renderiza nada
    });
  }

  return (
    <Document>
      {sections.length && (
        <Page size="A4" style={style_contract.page}>
          {pages.map((paragraph, index) => (
            <View key={index} style={style_contract.section}>
              {paragraph.includes('TABLE') ? (
                <View style={style_tables.table}>
                  {tables.length ? renderTable(handleTable(paragraph)) : ''}
                </View>
              ) : paragraph.trim().startsWith('**') &&
                paragraph.trim().endsWith('**') ? (
                <Text style={style_contract.subTitle}>
                  {paragraph.split('**')[1]}
                </Text>
              ) : paragraph.trim().startsWith('{CENTER}') ? (
                <Text style={style_contract.textCenter}>
                  {paragraph.split('{CENTER}')[1]}
                </Text>
              ) : paragraph.trim().startsWith('{BOLD}') ? (
                <Text style={style_contract.textBold}>
                  {paragraph.split('{BOLD}')[1]}
                </Text>
              ) : index === 0 ? (
                <Text style={style_contract.title}>{paragraph}</Text>
              ) : index > 0 && index < 5 && paragraph.length < 120 ? (
                <Text style={style_contract.subTitle}>{paragraph}</Text>
              ) : paragraph.toUpperCase().trim().startsWith('CLÁUSULA') &&
                paragraph.length < 120 ? (
                <Text style={style_contract.subTitle}>{paragraph}</Text>
              ) : (
                <Text style={style_contract.text}>{paragraph}</Text>
              )}
            </View>
          ))}
        </Page>
      )}

      {signatures.length && (
        <Page size="A4" style={style_signatures.page}>
          <View style={style_signatures.header}>
            <Image src="/logoOxpay.png" style={style_signatures.logoOxpay} />
            <View style={style_signatures.headerAuth}>
              <Text style={style_signatures.textAuth}>
                Autenticação eletrônica
              </Text>
              <Text style={style_signatures.textAuth}>
                Identificador: {id}/{contractNumber}
              </Text>
            </View>
          </View>
          <Text style={style_signatures.title}>Página de assinaturas</Text>
          <View style={style_signatures.container}>
            {signatures.map((data, i) => (
              <View key={i} style={style_history.item}>
                <View style={style_signatures.content}>
                  <Text style={style_signatures.signature}>
                    {capitalizeFirstLetter(
                      data.name.split(' ')[0].toLowerCase(),
                    )}
                    {'  '}
                    {data.name.split(' ')[data.name.split(' ').length - 1][0]}
                  </Text>
                  <Text style={style_signatures.text}>__________________</Text>
                  <Text style={style_signatures.textBold}>{data.name}</Text>
                  <Text style={style_signatures.text}>
                    {formatDocument(data.document)}
                  </Text>
                  <Text style={style_signatures.text}>Signatário</Text>
                </View>
              </View>
            ))}
          </View>
          {history.length && (
            <View style={style_history.history}>
              <View style={style_history.header}>
                <Text style={style_history.title}>Histórico</Text>
              </View>
              {history.map((data, index) =>
                index === 0 ? (
                  <View key={index} style={style_history.content} wrap={false}>
                    <View key={index} style={style_history.container}>
                      <View style={style_history.infoDate}>
                        <Text style={style_history.date}>
                          {formatDate(data.created_at)}
                        </Text>
                        <Text style={style_history.hour}>
                          {formatDate(data.created_at, 'HH:mm')}
                        </Text>
                      </View>
                      <View style={style_history.iconContainer}>
                        <Image
                          src={data.icon === 'addDocument' ? addDocument : view}
                          style={style_history.icon}
                        />
                      </View>
                      <View style={style_history.info}>
                        <Text style={style_history.text}>
                          <Text style={style_history.infoTextBold}>
                            {data.name}
                          </Text>{' '}
                          criou este documento. ( Email:{' '}
                          {data.email.toLowerCase()}, CPF:{' '}
                          {formatDocument(data.document)} )
                          {data?.ip_address
                            ? ` por meio do IP ${data?.ip_address}`
                            : ''}
                          .{' '}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View key={index} style={style_history.content} wrap={false}>
                    <View key={index} style={style_history.container}>
                      <View style={style_history.infoDate}>
                        <Text style={style_history.date}>
                          {formatDate(data.created_at)}
                        </Text>
                        <Text style={style_history.hour}>
                          {formatDate(data.created_at, 'HH:mm')}
                        </Text>
                      </View>
                      <View style={style_history.iconContainer}>
                        <Image
                          src={data.icon === 'view' ? view : signature}
                          style={style_history.icon}
                        />
                      </View>
                      <View style={style_history.info}>
                        <Text style={style_history.text}>
                          <Text style={style_history.infoTextBold}>
                            {data.name}
                          </Text>
                          , ( Email: {data.email.toLowerCase()}, CPF:{' '}
                          {formatDocument(data.document)} ){' '}
                          {data.icon === 'view'
                            ? ' visualizou este documento'
                            : ' assinou este documento'}
                          {data?.ip_address
                            ? ` por meio do IP ${data.ip_address}`
                            : ''}
                          .{' '}
                        </Text>
                      </View>
                    </View>
                  </View>
                ),
              )}
            </View>
          )}
          <View style={style_signatures.footer}>
            <Image src="/iconOx.png" style={style_signatures.iconOx} />
            <View style={style_signatures.footerContent}>
              <Text style={style_signatures.footerText}>
                Hash SHA256 do PDF original {hash}
              </Text>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
}
